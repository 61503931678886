import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-awesome-styled-grid"
import styled from "styled-components"
import Block from "../../components/Block"
import { TrackedLink } from "../../components/Button"
import DivHelper from "../../components/DivHelper"
import { Heading2 } from "../../components/Heading"
import { Hero, HeroContent, HeroHeading } from "../../components/Hero"
import Layout from "../../components/Layout"

const ProductBox = styled(DivHelper)`
  display: inline-block;
  margin-top: 20px;
  border-radius: 4px;
  border: 6px solid ${props => props.color};
  padding: 10px 20px;
  text-decoration: underline;
  max-width: 250px;
`

const ProductLabel = styled.p`
  text-align: center;
  font-weight: 600;
`

const Background = ({ image, className, children }) => {
  return (
    <BackgroundImage
      className={className}
      fluid={image.childImageSharp.fluid}
      style={{ height: "100%" }}
    >
      {children}
    </BackgroundImage>
  )
}

const ThcGehaltErhoehung = ({ location, data: { image } }) => {
  return (
    <Layout location={location} title="CBD Produkte mit 0,3% THC" mt0 light>
      <Hero heightVh={50}>
        <Background image={image}>
          <HeroContent align="flex-end">
            <Container>
              <Row>
                <Col>
                  <HeroHeading>CBD Produkte mit 0,3% THC</HeroHeading>
                </Col>
              </Row>
            </Container>
          </HeroContent>
        </Background>
      </Hero>
      <Container>
        <Block>
          <Row>
            <Col>
              <Heading2>
                Bundesregierung passt Vorschriften für THC-Gehalt in
                CBD-Produkten auf 0,3% an EU-Richtlinien an
              </Heading2>
              <p>
                In einer{" "}
                <a
                  href="https://www.bmel.de/SharedDocs/Pressemitteilungen/DE/2023/018-nutzhanf.html"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  aktuellen Pressemitteilung Nr. 18/2023 vom 15. Februar 2023
                  teilt das Bundesministerium für Ernährung und Landwirtschaft
                  (BMEL)
                </a>{" "}
                mit, dass es feste Änderungen für CBD-Produkte geben wird. Es
                geht dabei um den Beschluss des Bundeskabinetts,{" "}
                <strong>Änderungen im Betäubungsmittelgesetz</strong>{" "}
                vorzunehmen.
              </p>
              <p>
                In Deutschland werden die Vorgaben für den{" "}
                <strong>THC-Gehalt in CBD-Produkten</strong> geändert. Bislang
                lag der Grenzwert bei 0,2 Prozent THC, aber nun wird der{" "}
                <strong>Wert auf 0,3 Prozent angehoben</strong>. Diese Änderung
                ist darauf zurückzuführen, dass Deutschland seine nationalen
                Vorschriften an das EU-Recht anpasst. Die EU hat kürzlich ihre
                Richtlinien für den THC-Gehalt in CBD-Produkten aktualisiert.
                Die Richtlinien besagen, dass der THC-Gehalt in CBD-Produkten
                nicht höher als 0,3 Prozent sein darf. Dies bedeutet, dass
                Deutschland seine Vorschriften anpasst, um mit den
                EU-Richtlinien im Einklang zu stehen.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row align="center" justify="center">
            <Col md={8} xl={9}>
              <Heading2>
                Erhöhung des THC-Grenzwerts auf 0,3 Prozent eröffnet
                CBD-Unternehmen in Deutschland neue Möglichkeiten
              </Heading2>
              <p>
                Die Erhöhung des THC-Grenzwerts auf 0,3 Prozent wird es
                CBD-Unternehmen wie beispielsweise für{" "}
                <Link to="/vergleiche/cbd-oel/">CBD Öle</Link>,{" "}
                <Link to="/vergleiche/cbd-cremes-salben/">
                  CBD Cremes und Salben
                </Link>{" "}
                wie für{" "}
                <Link to="/vergleiche/cbd-fruchtgummis/">CBD Fruchtgummis</Link>{" "}
                in Deutschland ermöglichen, eine größere Auswahl an Produkten
                auf den Markt zu bringen. Dies wird auch dazu beitragen, dass
                sich die{" "}
                <strong>CBD-Industrie in Deutschland weiterentwickeln</strong>{" "}
                kann und die Verbraucher mehr Optionen haben, wenn es um die
                Verwendung von CBD-Produkten geht.
              </p>
              <p>
                Es ist jedoch wichtig zu beachten, dass die Änderung der
                THC-Grenzwerte nur für CBD-Produkte gilt. Der Besitz und Verkauf
                von Marihuana ist derzeit in Deutschland illegal und wird von
                der Polizei verfolgt. Es ist auch wichtig, dass Verbraucher bei
                der Verwendung von CBD-Produkten immer darauf achten, dass sie
                von einem <strong>vertrauenswürdigen Hersteller</strong> stammen
                und dass sie den THC-Gehalt des Produkts kennen.{" "}
                <Link to="/ratgeber/cbd-legalitaet/">
                  Schließlich bleiben die Voraussetzungen des
                  Betäubungsmittelgesetzes bestehen. Dies beinhaltet vor allem
                  den Ausschluss von Missbrauch zu Rauschzwecken
                </Link>
                .
              </p>
            </Col>
            <Col md={4} xl={3} align="center">
              <TrackedLink
                href="https://t.adcell.com/p/click?promoId=270410&slotId=98699&param0=https%3A%2F%2Fwww.naturecan.de%2Fproducts%2Fcbd-gummibaerchen"
                category="ratgeber"
                action="thc-gehalt-erhoehung"
                label="naturecan-ad"
              >
                <ProductBox color="#ff8573" center>
                  <StaticImage
                    src="../../assets/images/products/cbd-fruchtgummis/naturecan.png"
                    alt="Naturecan CBD Gummibärchen"
                    layout="constrained"
                    placeholder="blurred"
                    width={200}
                  />
                  <ProductLabel>CBD Gummibärchen von Naturecan</ProductLabel>
                </ProductBox>
                <img
                  src="https://t.adcell.com/p/view?promoId=270410&slotId=98699"
                  border="0"
                  width="1"
                  height="1"
                  alt=""
                />
              </TrackedLink>
              <TrackedLink
                href="https://t.adcell.com/p/click?promoId=194887&slotId=98699&param0=https%3A%2F%2Fcbdsfinest.de%2Fprodukt%2Fcbd-gummies%2F"
                category="ratgeber"
                action="thc-gehalt-erhoehung"
                label="cbds-finest-ad"
              >
                <ProductBox color="#ff8573" center>
                  <StaticImage
                    src="../../assets/images/products/cbd-fruchtgummis/cbds-finest.png"
                    alt="CBDs Finest CBD Fruchtgummis"
                    layout="constrained"
                    placeholder="blurred"
                    width={200}
                  />
                  <ProductLabel>CBD Gummibärchen von Naturecan</ProductLabel>
                </ProductBox>
              </TrackedLink>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2>Ausblick CBD mit einem THC-Gehalt von 0,3%</Heading2>
              <p>
                Dennoch ist es ein gutes Zeichen für die CBD-Produkte, da
                Unternehmen jetzt einen größeren Spielraum haben, um Produkte
                mit höherem CBD-Gehalt zu verkaufen. Ein höherer Grenzwert
                bedeutet, dass der{" "}
                <strong>THC-Gehalt natürlichen Schwankungen</strong> unterliegen
                kann, ohne dass das Produkt als illegal eingestuft wird.
              </p>
              <p>
                Dies ist besonders wichtig für Unternehmen, die CBD-Produkte
                herstellen und verkaufen, da sie CBD Produkte wie{" "}
                <Link to="/vergleiche/cbd-oel/">Öle</Link>,{" "}
                <Link to="/vergleiche/cbd-fruchtgummis/">CBD Fruchtgummis</Link>{" "}
                oder <Link to="/vergleiche/cbd-gleitgel/">Gleitgele</Link>{" "}
                anbieten können, ohne sich Sorgen machen zu müssen, dass sie den
                höheren Grenzwert überschreiten. Durch den höheren Grenzwert
                können Unternehmen CBD-Produkte mit höherem CBD-Gehalt
                herstellen und verkaufen, was letztendlich zu einer stärkeren
                Wirkung führen kann.
              </p>
              <p>
                Für Anbieter, die CBD-Produkte herstellen und vertreiben,
                bedeutet dies eine Änderung ihrer Produktions- und
                Vertriebsstrategien. Insgesamt ist die Anpassung der
                THC-Vorgaben in Deutschland eine positive Entwicklung für die
                CBD-Industrie und die Verbraucher. Unternehmen haben jetzt mehr
                Freiheit bei der Herstellung von Produkten mit höherem
                CBD-Gehalt, was letztendlich zu einer stärkeren Wirkung führen
                kann. Es ist jedoch wichtig, dass Unternehmen weiterhin
                sicherstellen, dass ihre Produkte den Standards und Richtlinien
                entsprechen, um das Vertrauen der Verbraucher zu gewinnen und zu
                erhalten. Wir sind gespannt, wann die ersten CBD Produkte mit
                einem <strong>THC-Gehalt von 0,3%</strong> auf den Markt kommen.
                Wir werden sie selbstverständlich einem{" "}
                <Link to="/">CBD Test unterziehen</Link> und freuen uns jetzt
                schon auf die nächsten <strong>CBD Testsieger</strong> in
                unserem CBD Vergleich.
              </p>
            </Col>
          </Row>
        </Block>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    image: file(relativePath: { eq: "stock/oil-water.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default ThcGehaltErhoehung
